@use "sass:meta" as ---qbzhh3poye;$bodycolor:#F1F3F6;
$bodywhite:#ffffff;
$headerbg:#1e335e;
$primary-color:#E46536;
$blue-color:#2176AE;
$black:#000000;
$white:#ffffff;
$error-color:#E24141;
$success-color:#31B93E;

$opacity-12:0.12;
$opacity-37:0.37;
$opacity-54:0.54;
$opacity-87:0.87;

$black-3:rgba($black,.03);
$black-12:rgba($black,.12);
$black-37:rgba($black,.37);
$black-54:rgba($black,.54);
$black-87:rgba($black,.87);

$white-12:rgba($white,.12);
$white-37:rgba($white,.37);
$white-54:rgba($white,.54);
$white-87:rgba($white,.87);
;@include ---qbzhh3poye.load-css("sass-embedded-legacy-load-done:31");