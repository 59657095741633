@use "sass:meta" as ---qbzhh3poye;.lead-detail-tabs {
    .inventory-funding-doc-verfiaction {

        .menu-tab-left-panel {
            padding-left: 0px;
            padding-right: 0px;

            ul {
                li {
                    a {
                        margin-bottom: 0px;
                        padding: 10px 33px;

                        &.active,
                        &:hover {
                            background: #EFF2F9;
                        }

                        &::before {
                            content: '';
                            background: $heading-color;
                            height: 5px;
                            width: 5px;
                            border-radius: 100%;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            margin-left: -10px;
                        }
                    }


                }
            }

        }

        .image-tab-panel {
            width: calc(70% - 20px);
        }

        .form-tab-right-panel {
            width: calc(30% - 20px);
        }

        .doument-gallery-tabs {
            .image-opt {
                position: inherit;
            }
        }

        .image-tab-panel,
        .form-tab-right-panel {
            margin-top: 0px !important;
        }

        .doument-gallery-tabs {
            h3 {
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                text-align: left;
                color: $heading-color;

            }

            .verfication-img {
                padding: 30px 0 10px;
            }
        }
    }

}

.verfiaction-acttion-btn {
    .btn-line {
        min-width: 100%;
    }
}

.upload-info-verfication {
    .image-form-outer {
        width: 70%;

        .image-tab-panel {
            width: 100%;
            margin-top: 0px;

            .doument-gallery-tabs {
                display: flex;
                align-items: center;

                .doc-list-image {
                    width: calc(100% - 20px);

                    .dropdown {
                        position: relative;

                        .dropdown-menu {
                            width: 400px;

                            &.show {
                                overflow: auto;
                            }
                        }
                    }

                    .file-submit-sec {
                        width: 100%;
                    }

                    &:first-child {
                        padding-right: 20px;
                        border-right: 1px solid $border-color;
                    }

                    &:nth-child(2) {
                        padding-left: 20px;
                    }
                }

                .image-opt {
                    position: inherit;
                }

                .image-tagning-drop-down {
                    position: inherit;
                    margin-top: 0px;

                    button {
                        border: 0px;
                        width: 220px;
                    }
                }

                .btn-submission-approved {
                    display: none;
                }
            }

        }

    }

    .form-tab-right-panel {
        width: 30%;
        margin-top: 0px;
    }

    .material {
        input {
            &.form-input {
                height: 42px;
            }
        }
    }

}

.right-panel-scroll {
    max-height: 503px;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 5px 20px 0 20px;
    margin-right: -20px;
}

.btn-submission-approved {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.car-valuation-tab {
    .image-form-outer {
        width: 28%;
        height: auto;

        .image-tab-panel {
            width: 100%;
        }
    }
    

    .form-tab-right-panel {
        width: 72%;

        .material {
            input {
                &.form-input {
                    height: 42px;
                }
            }
        }
    }

    .image-opt {
        position: inherit;
    }

    .lead-form-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h2 {
            padding-bottom: 0px;
        }
    }
.car-valuation-form{
    fieldset{
        margin-bottom: 15px;
    }
}
}

.valuation-form-card-outer {
    display: flex;
    justify-content: space-between;

    .car-valuation-form {
        width: 49%;

    }

    .car-valuation-card-outer {
        width: 49%;
        padding: 0px 0 0 15px;

        .valuation-card {
            box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.04);
            border: 1px solid rgba(224, 224, 224, 1);
            border-radius: 10px;
            margin-bottom: 10px;
            color: $heading-color;
            padding: 20px;

            span {
                font-size: 22px;
                font-weight: 600;
                line-height: 33px;
                display: block;
            }

            label {
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
            }

            p {
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;
                color: rgba($heading-color, 0.5);

            }
        }
        &.final-output-right-panel{
            width: calc(100% - 408px);
            padding-left: 40px;
            .accordion__button{
                h3{
                    font-size: 16px;
                    font-weight: 500;
                    color: $heading-color;
                }
            }
        }
    }

    .edit-laon-amt-txt {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: $accent-color;
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }

    .btn-primary {
        min-width: auto;
        width: 100%;
    }

    .image-opt {
        position: inherit;
    }
}

.edit-loan-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;

    span {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: $heading-color;
    }

    i {
        cursor: pointer;
        font-size: 18px;
        color: $heading-color;

    }
}

.disbursal-credit-approval {
    background-color: $white;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 10px;

    .disbursal-credit-outer {
        padding: 20px 0;

        .disbursal-opt-card-outer {
            display: flex;

            .disbursal-card {
                box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.04);
                border: 1px solid rgba(224, 224, 224, 1);
                border-radius: 10px;
                width: 220px;
                padding: 30px 10px;
                margin-right: 10px;

                span {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 27px;
                    text-align: center;
                    color: $heading-color;
                    display: block;
                }

                label {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: center;
                    color: $heading-color;
                    display: block;

                }
            }
        }
    }
}

.disbursal-credit-peanding-table-outer {
    table {
        tr {

            th,
            td {
                white-space: normal;

                &:first-child {
                    width: 15%;
                }

                &:nth-child(2) {
                    width: 6%;
                }

                &:nth-child(3) {
                    width: 10%;
                }

                &:nth-child(4) {
                    width: 10%;
                }

                &:nth-child(5) {
                    width: 10%;
                }

                &:nth-child(6) {
                    width: 10%;
                }

                &:nth-child(7) {
                    width: 10%;
                }

                &:nth-child(8) {
                    width: 12%;
                }

                &:nth-child(9) {
                    width: 10%;
                }
            }
        }
    }
}

.credit-disbural-amt-detail {
    padding: 20px 0;

    ul {
        display: flex;

        li {
            padding-right: 40px;
            color: $heading-color;

            label {
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                text-align: left;
                display: block;
            }

            span {
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                text-align: center;

            }
        }
    }
}

.loan-dictionary-tab {
    background-color: $white;
    padding: 20px;
    margin: 20px 0px;
    border-radius: 10px;

    .loan-disctory-table-outer {
        table {
            tbody {
                border: 1px solid rgba(0, 0, 0, 0.06);
                border-radius: 8px;
                border-top: 0px;
            }

            tr {
                th {
                    border-radius: 8px 0 0 0;

                    &:last-child {
                        border-radius: 0 8px 0 0;
                    }
                }

                th,
                td {
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }

    .lead-form-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        h3 {
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
            text-align: left;
            color: $heading-color;
        }

        .btn-line {
            min-width: auto;
            padding: 0px 20px;
            display: flex;
            align-items: center;

            span {
                margin-left: 5px;
            }
        }
    }
}

.vehicle-photos-detail {
    padding-top: 30px;

    .heading-txt {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
            text-align: left;
            color: $heading-color;
        }

        .btn-line {
            min-width: auto;
            padding: 0px 20px;
            display: flex;
            align-items: center;

            span {
                margin-left: 5px;
            }
        }
    }

    .image-opt {
        position: inherit;

        .ic-Download-icon {
            font-size: 14px;
        }
    }

    .verfication-img {
        padding: 20px 0;
    }
}

.photos-gallery-sec {
    background-color: rgba(241, 244, 252, 1);
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;

    .doument-view-list-gallery {
        span {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            color: $heading-color;
        }
    }
}

.loan-financer-detail {
    padding: 30px 0px;

    h3 {
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $heading-color;
        padding-bottom: 10px;
        display: block;

    }
}

.doc-varfiaction-tab-outer {
    .tab-list {
        display: inline-block !important;
        width: 100%;
    }

    .nav-pills {
        background: transparent;
        width: 100%;
        box-shadow: none;
        margin: 0px;
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;

        .nav-item {
            a {
                background: transparent;
                border-radius: 0px;
                justify-content: flex-start;
                margin-right: 0px;

                span {
                    margin-left: 10px;
                }

                &.active,
                &:hover {
                    background: rgba(239, 242, 249, 1);
                    color: $heading-color;

                }

                &.completed {
                    &:after {
                        content: "\e927";
                        color: $highlight-color;
                        font-family: "dc";
                        position: absolute;
                        right: 20px;
                        left: inherit;
                        border: 0px;
                        font-weight: bold;
                    }

                }
            }
        }
    }

}

.inventory-funding-doc-verfiaction {

    .icn-pre-slide,
    .icn-next-slide {
        top: 39% !important;

        button {
            background: $white;
            border-radius: 30px;
            height: 30px;
            width: 30px;
            border: 1px solid $border-color;
        }
    }

    .image-tab-panel {
        min-height: 590px;
    }

}

.upload-info-verfication {
    .verfication-img {
        position: relative;
    }

    .icn-pre-slide,
    .icn-next-slide {
        button {
            background: $white;
            border-radius: 30px;
            height: 30px;
            width: 30px;
            border: 1px solid $border-color;
        }
    }

    .icn-pre-slide {
        left: -20px !important;
    }

    .icn-next-slide {
        right: -20px !important;
    }
}

.car-valuation-tab {

    .icn-pre-slide,
    .icn-next-slide {
        top: 36% !important;

        button {
            background: $white;
            border-radius: 30px;
            height: 30px;
            width: 30px;
            border: 1px solid $border-color;

        }
    }

    .icn-pre-slide {
        left: 10px !important;
    }

    .icn-next-slide {
        right: 10px !important;
    }

}

.calculator-popup-form {
    fieldset {
        margin-bottom: 20px;
    }

    .modal-main {
        width: 630px;
    }

}

.inventory-funding-list-table {
    table {
        tr {
            th {
                &:first-child {
                    width: 13%;
                }

                &:nth-child(2) {
                    width: 13%;
                }

                &:nth-child(3) {
                    width: 11%;
                }

                &:nth-child(4) {
                    width: 11%;
                }

                &:nth-child(5) {
                    width: 11%;
                }

                &:nth-child(9) {
                    width: 11%;
                }

                &:nth-child(10) {
                    width: 11%;
                }
            }
        }
    }
}

.cancel-lead-popup {
    .modal-main {
        width: 400px;
    }

    .btn-primary {
        min-width: 140px;
    }
}

.marked-submission-popuop {
    .modal-main {
        width: 410px;
    }

    .btn-primary {
        min-width: 140px;
    }

    h3 {
        color: $heading-color;
    }
}

.approved-tag-icn {
    position: absolute;
    right: 10px;
    color: #30b38a;
    top: 9px;
    font-size: 16px;
}

.verify-doc-upload-img {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;

    .img-upload-outer-input {
        width: 105px;
        height: 42px;
        cursor: pointer;
        overflow: hidden;

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.list-input-field {
    position: relative;

    input {
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        width: 100%;
        padding: 9px;
        font-size: 11px;
    }
}


.upload-id-proof-btn {
    position: relative;
    text-align: center;
    padding-bottom: 20px;

    input {
        position: absolute;
        opacity: 0;
        height: 100%;
        width: 200px;
        cursor: pointer;
    }

    button {
        cursor: pointer;
    }
}

.download-btn {
    min-width: 140px;
    margin-left: 12px;
}

.exterior-tab-outer {
    h4{
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: $heading-color;
        padding: 20px 0 5px;
    }
    .chip-control {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        cursor: pointer;
        font-size: 13px;
        text-align: center;
        margin: 0px 8px 0px 0px;
        border-radius: 8px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &.hide {
            display: none;
        }

        span {
            display: block;
            padding: 7px;
            border-radius: 8px;
            background: $white;
            font-size: 12px;
            line-height: 14px;
            color: $text-color;
            vertical-align: top;
            transition: background 0.2s;
            border: 1px solid $border-color;
            min-width: 72px;
            color: rgba($heading-color, 0.5);

            &.checked {
                background: #E2FFEA;
                border-color: #A6DCB5;
                color: $heading-color;
            }
        }

        input {
            position: absolute;
            // z-index: -1;
            opacity: 0;
            top: 0;
            appearance: auto;
            width: 100%;
            height: 100%;
            cursor: pointer;
            left: 0px;

            &:checked~span {
                background: #E2FFEA;
                border-color: #A6DCB5;
                color: $heading-color;
            }
        }

        &.chip-fair {
            span {
               
                &.checked {
                    background: #A3CDEB;
                    border-color: #E2F3FF;
                }
            }

            input {
                &:checked~span {
                    background: #A3CDEB;
                    border-color: #E2F3FF;
                }
            }
        }

        &.chip-poor {
            span {
                &.checked {
                    background: #FFE8E8;
                    border-color: #F0BABA;
                }
            }

            input {
                &:checked~span {
                    background: #FFE8E8;
                    border-color: #F0BABA;
                }
            }
        }
    }

}
.bg-white{
    background: $white;
}
.image-outer-infection{
    width: 40% !important;
    .doc-list-image{
        border-right: 0px !important;
    }
}
.infection-report-outer{
        width: calc(60% - 20px) !important;
        margin-left: 20px;
        .nav-pills{
            box-shadow: none !important;
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;
            position: relative;
            .nav-item{
                margin-right: 45px;
                &:last-child{
                    margin-right: 0px !important;
                }
            }
            &::after{
                content: '';
                background: $border-color;
                width: calc(100% - 15px);
                height: 1px;
                position: absolute;
                bottom: 10px;
            }
        }
    
}
.edit-loan-btn{
    min-width: auto !important;
    width: 100%;
    padding: 0px 20px;
}
.valuation-progess-bar{
    width: 75px;
}
.progess-bar-rp-outer{
    display: flex;
    align-items: center;
    margin-top: -1rem;
    .progess-bar-outer{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 40%;
        text-align: center;
        label{
            display: block;
            width: 100%;
            font-size: 12px;
        }
    }
}
.slider-pre-nex-icn{
    ul{
        li{
            
            a{
                background: $white;
                height: 36px;
                width: 36px;
                border-radius: 36px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $heading-color;
                font-size: 16px;
                
            }
        }
    }
}
.slider-img-pdf{
    display: inline-block;
    width: 100%;
}
.calculator-card-left{
    width:408px ;

.loan-amount-offer-card{
    background: url(../../images/laon-amt-offer-bg.png) no-repeat top left;
    height: 222px;
    width: 100%;
    padding: 40px;
    margin-bottom: 20px;
    .sub-heading{
        font-size: 12px;
        display: block;
        text-align: center;
        color: $heading-color;
    }
    h3{
        font-size: 24px;
        font-weight: 600;
        color: #30A600;
        display: block;
        padding: 8px 0 15px;
        text-align: center;
    }
    ul{
        display: flex;
        align-items: center;
        margin: 0px -5px;
        li{
            background: $white;
            border-radius: 10px;
            width: 33.33%;
            margin: 0px 5px;
            padding: 15px 8px;
            border: 1px solid $white;
            position: relative;
            span{
                font-size: 11px;
                color: $heading-color;
                display: block;
                text-align: center;
            }
            &.active{
                border-color: #0BBDDD;
            }
            img{
                position: absolute;
                right: 0px;
                top: 0px;
                height: 14px;
                width: 14px;
            
            }
        }
    }
}
.car-valuation-score {
    height: 138px !important;
}
.edit-loan-amt-txt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 20px;
    span{
        font-size: 16px;
        font-weight: 500;
        color: $heading-color;
    }
    i{
        font-size: 25px;
        color: $heading-color;
    }
}
}
.final-output-table{
    padding: 0px 10px 10px;
    table{
        thead{
            tr{
                background: transparent;
                border-radius: 0px;
                border-bottom: 1px solid $border-color;
                th{
                    white-space: normal;
                    &:first-child{
                        padding-left: 0px;
                    }
                    &:last-child{
                        padding-right: 0px;
                    }
                }
            }
        }
        tbody{
            tr{
               td{
                &:first-child{
                    padding-left: 0px;
                }
                &:last-child{
                    padding-right: 0px;
                }
               }
              
            }
        }
    }
    .view-detail-txt{
        display: flex;
        align-items: center;
        font-size: 10px;
        color: $heading-color;
        padding: 20px 0px 10px;
        span{
            font-size: 10px;
            font-weight: 500;
            color: #0BBDDD;
            cursor: pointer;
            margin-left: 3px;
        }
        i{
            margin-right: 5px;
            font-size: 14px;
        }
    }
}

;@include ---qbzhh3poye.load-css("sass-embedded-legacy-load-done:44");