@use "sass:meta" as ---qbzhh3poye;.loan-detail-filed-bg{
  border: 1px solid $border-color;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  h2{
    background: #e2e7f1;
    padding: 10px 10px !important;
    margin-bottom: 10px;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
  }
  &.loan-input-filed-bg{
    h2{
      margin-bottom: 20px;
    }
  }
}
.loan-payment-sec{
  .material{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
  }
  .form-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-right: 10px;
    color: $heading-color;
    position: initial;
}
.form-input{
  width: 180px;
}
}
.right-panel-scroll{
  max-height: 750px;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% + 60px);
  margin-left: -30px;
  padding: 0px 25px 0 30px;
}
;@include ---qbzhh3poye.load-css("sass-embedded-legacy-load-done:41");