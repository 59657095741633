@use "sass:meta" as ---qbzhh3poye;// @use postcss-preset-env {
//   stage: 0;
// }

/** BEGIN CSS NEEDED FOR SWITCH **/
.on-off-toggle {
  width: 56px;
  height: 24px;
  position: relative;
  display: inline-block;
}

.on-off-toggle__slider {
  width: 56px;
  height: 24px;
  display: block;
  border-radius: 34px;
  background-color: rgba(171, 171, 171, 0.2);
  transition: background-color 0.4s;

  &:before {
    content: "";
    display: block;
    background-color: #ababab;
    box-shadow: 0 0 0 1px #ababab;
    bottom: 3px;
    height: 18px;
    left: 3px;
    position: absolute;
    transition: 0.4s;
    width: 18px;
    z-index: 5;
    border-radius: 100%;
    font-weight: 400;
  }

  &:after {
    display: block;
    line-height: 24px;
    font-size: 12px;
    font-weight: bold;
    content: "Inactive";
    color: $promotional-color-2;
    padding-left: 65px;
    transition: all 0.4s;
  }
}

.on-off-toggle__input {
  /*
    This way of hiding the default input is better 
    for accessibility than using display: none;
  */
  position: absolute;
  opacity: 0;
}

.on-off-toggle__input:checked + .on-off-toggle__slider {
  background-color: rgba($accent-color, 0.2);

  &:before {
    transform: translateX(32px);
  }
  &:after {
    content: "Active";
    color: $heading-color;
    padding-left: 63px;
  }
  &::before {
    background-color: $accent-color;
    border-color: $accent-color;
    box-shadow: 0 0 0 1px $accent-color;
  }
}

;@include ---qbzhh3poye.load-css("sass-embedded-legacy-load-done:34");